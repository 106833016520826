.app-button {
  min-width: 250px;
  min-height: 50px;
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 25px;
}

.app-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.solid-button {
  color: #363b7f;
  background-color: #56fdd2;
  border: 1px solid #56fdd2;
  transition: all 0.5s;
  cursor: pointer;
}

.anim:enabled span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.solid-button:enabled span:after {
  content: "\00bb";
  color: #363b7f;
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.anim:hover:enabled span {
  padding-right: 25px;
}

.anim:hover:enabled span:after {
  opacity: 1;
  right: 0;
}

.outline-button {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all 0.5s;
  cursor: pointer;
}

.outline-button:enabled span:after {
  content: "\00bb";
  color: #fff;
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.transparent-button {
  background-color: transparent;
  color: #56fdd2;
  border: 1px transparent;
  cursor: pointer;
}
