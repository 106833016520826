.login-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.text-inputs-wrap {
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}
