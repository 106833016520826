.email-confirmed-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email-confirmed-widget-title {
  margin-top: 1rem;
}

.email-confirmed-widget-info {
  display: flex;
  flex-direction: column;
  margin: auto 2rem;
}

@media (max-width: 500px) {
  .email-confirmed-widget-info {
    margin: auto 1rem;
  }
}
