.deep-link-screen-wrap {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
}

.deep-link-screen-logo-wrap .hmp-logo {
  max-width: 265px;
}

.deep-link-screen-logo-wrap {
  max-width: 100%;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 6.5rem;
}

.deep-link-screen-title {
  margin-top: 6.625rem;
  font-size: 2.125rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}
.deep-link-screen-description {
  font-size: 1.375rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  max-width: 660px;
  margin-bottom: 2.5rem;
}

@media (max-width: 500px) {
  .deep-link-screen-logo-wrap {
    margin-top: 8rem;
  }
}
