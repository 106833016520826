.survey-widget {
  display: flex;
  flex-direction: row;
}

.survey-widget button {
  margin-left: 0;
}

.survey-widget-wrap {
  display: flex;
  flex-direction: column;
}

.survey-widget-info-t1,
.survey-widget-info-t2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.75rem;
  margin: 2.5rem 2.5rem 2rem 2rem;
}

.survey-widget-description {
  margin-bottom: 1rem;
}

@media (max-width: 500px) {
  /* Remove min-width for buttons */
  .survey-widget-wrap button {
    min-width: unset;
    padding: 0.75rem 1.5rem;
  }

  .survey-widget-info-t1,
  .survey-widget-info-t2 {
    padding-bottom: 1.25rem;
    margin: 2.25rem 0.85rem 0.5rem 0.85rem;
  }
}
