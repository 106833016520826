/* CSS shared across different widgets */

.widget-container {
  max-width: 724px;
  z-index: 2;
}

.widget-card {
  padding: 2rem 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  margin: 0.75rem 0 1.5rem 0;
}

.widget-title {
  font-size: 2.125rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 0.6rem;
}

.widget-description {
  font-size: 1.375rem;
  text-align: left;
}

.text-disabled {
  color: rgba(255, 255, 255, 0.3);
}

.icon-download {
  width: 45px;
  height: 62px;
  margin: 2rem 2.75rem;
}

@media (max-width: 500px) {
  /* Decrease size of download icon */
  .icon-download {
    width: 30px;
    height: 41px;
    margin: 2rem 1.5rem 2rem 2rem;
  }

  /* Decrease padding of widget card */
  .widget-card {
    padding: 1rem 0.5rem;
  }
}
