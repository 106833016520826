.reset-password {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.reset-password-code-sent {
  margin: 1.5rem 1rem 0.75rem 1rem;
  max-width: 100%;
  width: 360px;
}

.reset-password-code-sent-success {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.reset-password-create-password {
  text-align: left;
  margin: 1rem 0.5rem 0rem 0.5rem;
}

.reset-password-code-sent span {
  display: block;
}

.reset-password-submit-btn-wrap {
  margin: 1rem 0;
}
