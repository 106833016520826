.invitation-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.header-text {
  max-width: 616px;
  font-size: 22px;
  text-align: center;
  color: #fff;
}

.text-input-wrap {
  max-width: 100%;
  align-self: center;
}
