.front-door {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.front-door-header {
  max-width: 616px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.front-door-buttons {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
