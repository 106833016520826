.signup-form-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}
.signup-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  z-index: 1;
}

.cohort-title {
  max-width: 451px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

.header-text {
  max-width: 451px;
  font-size: 22px;
  text-align: center;
  padding: 0 0.5rem;
}

.signup-check {
  width: 345px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.signup-check p {
  width: 295px;
  text-align: left;
}

.signup-check a {
  color: #fff;
  cursor: pointer;
}

.signup-health {
  max-width: 345px;
  opacity: 0.5;
  font-size: 12px;
}

.already-signed-in {
  padding: 25px;
}

.login-link {
  text-decoration: underline;
  cursor: pointer;
}

.signup-input {
  padding: 8px;
  max-width: 100%;
}

.signup-checks-wrap {
  max-width: 100%;
  align-self: center;
}
