.error-screen-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.error-message {
  max-width: 500px;
  font-size: 1.25rem;
  text-align: center;
  padding: 1rem;
}