.check-icons-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem;
}

.check-icons-wrap > .icon-check {
  margin: 0;
}

/* Custom dash line */
.line-dashed {
  width: 1px;
  height: 228px;
  background-image: linear-gradient(to right, #979797 50%, transparent 50%),
    linear-gradient(to right, #979797 50%, transparent 50%),
    linear-gradient(to bottom, #979797 50%, transparent 50%),
    linear-gradient(to bottom, #979797 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
}
