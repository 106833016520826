.app-installation-widget {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.app-installation-widget-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1.35rem 2rem;
}

.app-installation-description {
  margin-bottom: 0.875rem;
}

.app-installation-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}
/* Adjust margins for download buttons */
.app-installation-links > button {
  margin: 0.5rem 0;
}

@media (max-width: 500px) {
  .app-confirmed-widget-info {
    margin: auto 1rem;
  }

  /* Remove min-width of buttons */
  .app-installation-links > button {
    min-width: unset;
    padding: 0.75rem 1.5rem;
  }
}
