.reset-password-email {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.reset-password-email-instruction {
  margin: 1.5rem 1rem 1.75rem 1rem;
  max-width: 100%;
  width: 360px;
}

.text-inputs-wrap {
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.reset-password-email-submit-btn-wrap {
  margin: 1rem 0;
}
