.confirm-form-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}
.confirm-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  z-index: 1;
}

.confirm-header {
  max-width: 100%;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.confirm-text {
  max-width: 100%;
  font-size: 22px;
  text-align: center;
  color: #fff;
}

.confirm-text-email {
  display: block;
  font-weight: bold;
  margin: 0;
  word-break: break-all;
}

.resent {
  color: #56fdd2;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.confirm-list {
  max-width: 345px;
  font-size: 18px;
  color: #fff;
  text-align: left;
}

.confirm-list span {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.confirm-text-bold {
  font-weight: bold;
}
