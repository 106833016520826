/* Log out button */
button#btn-logout {
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  min-width: unset;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
}

@media (max-width: 500px) {
  /* Decrease margin of logout button */
  button#btn-logout {
    top: 0;
    right: 0.75rem;
  }
}
