.home-screen-wrap {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
}

.home-screen-header {
  max-width: 451px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

.home-screen-logo-wrap {
  margin-top: 10rem;
  margin-bottom: 3rem;
}

/* Need help button */
.home-screen-wrap > button#btn-need-help {
  z-index: 2;
  font-size: 1.125rem;
  font-weight: 700;
  min-width: unset;
}

@media (max-width: 500px) {
  .home-screen-logo-wrap {
    margin-top: 8rem;
  }
}
