.app {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background-image: linear-gradient(#373c7f, #09192d);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 300px;
  background: url("./assets/stars.svg") center/auto 100%;
}

* {
  outline: none;
}

@media (max-width: 500px) {
  .stars {
    max-height: 170px;
  }
}
